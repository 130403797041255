import { Text } from "@clipboard-health/ui-react";

export function UploadTimesheetContent() {
  return (
    <>
      <Text>We are verifying your shift.</Text>
      <Text>Please upload a timesheet if you haven&apos;t already done so.</Text>
    </>
  );
}
