import { Text } from "@clipboard-health/ui-react";
import { type ReactElement } from "react";

import { type ShiftStateMetadata } from "../types";

interface Props {
  shiftStateMetadata: ShiftStateMetadata;
}

export function BreakStartContent(props: Props): ReactElement {
  const {
    shiftStateMetadata: {
      isShiftOver,
      displayTimeSheetSummary,
      requiresLunchBreak,
      isTimeSheetEditDisabled,
    },
  } = props;

  return (
    <>
      <Text>
        {requiresLunchBreak
          ? "You can start your 30-minute mandatory break at any point during the shift."
          : "You can start or skip your break at any point during your shift."}
      </Text>
      {isShiftOver ? (
        <>
          <Text>However, the shift has ended.</Text>
          {!isTimeSheetEditDisabled && displayTimeSheetSummary && (
            <Text>Don&apos;t worry - you can edit your timesheet at the end.</Text>
          )}
        </>
      ) : null}
    </>
  );
}
