import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useEffect } from "react";

import { BreakEndContent } from "./NextStateContent/BreakEndContent";
import { BreakStartContent } from "./NextStateContent/BreakStartContent";
import { ClockInContent } from "./NextStateContent/ClockInContent";
import { ClockOutContent } from "./NextStateContent/ClockOutContent";
import { UploadTimesheetContent } from "./NextStateContent/UploadTimesheetContent";
import { NextShiftState, type ShiftStateDependencies, type ShiftStateMetadata } from "./types";

interface Props {
  nextState: NextShiftState;
  shiftStateMetadata: ShiftStateMetadata;
  shiftStateDependencies: ShiftStateDependencies;
}

export function ShiftStateContent(props: Props) {
  const { nextState, shiftStateMetadata, shiftStateDependencies } = props;
  const { _id: shiftId } = shiftStateDependencies;

  useEffect(() => {
    // FIXME: Use useLogEffect instead.
    // Our dependencies (nextState, shiftStateMetadata and shiftId) are always updated at the same time inside ShiftStateInfo.tsx.
    // shiftStateMetadata is cached by useQuery.
    // This is implicit and not intrinsic to this useEffect call, though.
    logEvent(APP_V2_USER_EVENTS.VIEWED_WHATS_NEXT, {
      shiftId,
      nextState,
      ...shiftStateMetadata,
    });
  }, [nextState, shiftStateMetadata, shiftId]);

  switch (nextState) {
    case NextShiftState.CLOCK_IN: {
      return (
        <ClockInContent
          shiftStateMetadata={shiftStateMetadata}
          shiftStateDependencies={shiftStateDependencies}
        />
      );
    }

    case NextShiftState.BREAK_START: {
      return <BreakStartContent shiftStateMetadata={shiftStateMetadata} />;
    }

    case NextShiftState.BREAK_END: {
      return (
        <BreakEndContent
          shiftStateMetadata={shiftStateMetadata}
          shiftStateDependencies={shiftStateDependencies}
        />
      );
    }

    case NextShiftState.CLOCK_OUT: {
      return (
        <ClockOutContent
          shiftStateMetadata={shiftStateMetadata}
          shiftStateDependencies={shiftStateDependencies}
        />
      );
    }

    case NextShiftState.UPLOAD_TIMESHEET: {
      return <UploadTimesheetContent />;
    }

    default: {
      throw new Error(`Can't display the shift status ${nextState as string}.`);
    }
  }
}
