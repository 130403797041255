import { type CbhAppInfo } from "@src/appV2/lib/index";
import { isSolveUnpaidBreaksFeatureEnabled } from "@src/appV2/Shifts/utils/isSolveUnpaidBreaksFeatureEnabled";

import { NextShiftState, ShiftState, type ShiftStateData } from "../types";

type NextShiftStateMap = Partial<Record<ShiftState, NextShiftState>>;

export const instantPayNextShiftStateMap: NextShiftStateMap = {
  [ShiftState.CLAIMED]: NextShiftState.CLOCK_IN,
  [ShiftState.CLOCKED_IN]: NextShiftState.BREAK_START,
  [ShiftState.ON_BREAK]: NextShiftState.BREAK_END,
  [ShiftState.ENDED_BREAK]: NextShiftState.CLOCK_OUT,
  [ShiftState.SKIPPED_BREAK]: NextShiftState.CLOCK_OUT,
};

export const instantPaySolveUnpaidBreaksNextShiftStateMap: NextShiftStateMap = {
  [ShiftState.CLAIMED]: NextShiftState.CLOCK_IN,
  [ShiftState.CLOCKED_IN]: NextShiftState.CLOCK_OUT,
};

export const nonInstantPayNextShiftStateMap: NextShiftStateMap = {
  [ShiftState.MISSING_SIGNATURE]: NextShiftState.UPLOAD_TIMESHEET,
  [ShiftState.MISSING_CLOCK_IN]: NextShiftState.UPLOAD_TIMESHEET,
  [ShiftState.MISSING_CLOCK_OUT]: NextShiftState.UPLOAD_TIMESHEET,
  [ShiftState.UNVERIFIED]: NextShiftState.UPLOAD_TIMESHEET,
};

interface GetNextShiftState extends ShiftStateData {
  appInfo?: CbhAppInfo;
}

export function getNextShiftState(shiftStateData: GetNextShiftState): NextShiftState | undefined {
  const { metadata, state, appInfo } = shiftStateData;

  if (metadata.isInstantPay) {
    const {
      metadata: { solveUnpaidBreaksRolloutStageWithTargetingConfig, isSignatureSubmission },
    } = shiftStateData || { metadata: {} };
    if (
      isSignatureSubmission &&
      isSolveUnpaidBreaksFeatureEnabled({
        solveUnpaidBreaksRolloutStageWithTargetingConfig,
        appInfo,
      })
    ) {
      return instantPaySolveUnpaidBreaksNextShiftStateMap[state];
    }

    return instantPayNextShiftStateMap[state];
  }

  return nonInstantPayNextShiftStateMap[state];
}
