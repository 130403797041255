import { Text } from "@clipboard-health/ui-react";
import { differenceInHours } from "date-fns";
import { formatInTimeZone, toDate } from "date-fns-tz";
import pluralize from "pluralize";

import { DEFAULT_TIMEZONE } from "./constants";

interface ShiftTimeLabelProps {
  shiftStart: string | Date;
  shiftEnd: string | Date;
  timeZone?: string;
  shiftDuration?: number;
}

export function ShiftTimeLabel(props: ShiftTimeLabelProps) {
  const { shiftStart, shiftEnd, timeZone, shiftDuration } = props;

  const timezone = timeZone ?? DEFAULT_TIMEZONE;
  const timeDuration = shiftDuration ?? differenceInHours(toDate(shiftEnd), toDate(shiftStart));
  const formattedDuration = `${timeDuration ?? 0} ${pluralize("hr", timeDuration)}`;
  const formattedStartTime = formatInTimeZone(shiftStart, timezone, "hh:mm a");
  const formattedEndTime = formatInTimeZone(shiftEnd, timezone, "hh:mm a");

  return (
    <Text variant="body2" color="inherit">
      {formattedStartTime} - {formattedEndTime} ({formattedDuration})
    </Text>
  );
}
