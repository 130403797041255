import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { MANDATORY_BREAK_DURATION_MINUTES } from "@src/appV2/Shifts/Shift/constants";
import { addMinutes, parseISO } from "date-fns";
import { type ReactElement } from "react";

import { type ShiftStateDependencies, type ShiftStateMetadata } from "../types";

interface Props {
  shiftStateMetadata: ShiftStateMetadata;
  shiftStateDependencies: ShiftStateDependencies;
}

export function BreakEndContent(props: Props): ReactElement {
  const {
    shiftStateMetadata: {
      isLate,
      isShiftOver,
      displayTimeSheetSummary,
      requiresLunchBreak,
      isTimeSheetEditDisabled,
    },
    shiftStateDependencies,
  } = props;

  const isBreakEndLateTextVisible = isLate && !isShiftOver;
  const isEditTimesheetTextVisible =
    !isTimeSheetEditDisabled && displayTimeSheetSummary && (isLate || isShiftOver);
  const isMandatoryBreakTextVisible = !isLate && !isShiftOver && requiresLunchBreak;

  const lunchStartTime = shiftStateDependencies.lunchInOut?.start;

  return (
    <>
      {isMandatoryBreakTextVisible ? (
        <Text>
          You are now on your break
          {isDefined(lunchStartTime)
            ? ` until ${formatTime(
                addMinutes(parseISO(lunchStartTime), MANDATORY_BREAK_DURATION_MINUTES),
                { timeZone: shiftStateDependencies.facility.tmz }
              )}`
            : ""}
          . This is a facility-required 30-minute break, so you shouldn&apos;t end it early
        </Text>
      ) : (
        <Text>You are now on your break.</Text>
      )}

      {isBreakEndLateTextVisible && (
        <Text>It&apos;s been more than 1 hour but you haven&apos;t ended your break yet.</Text>
      )}
      {isShiftOver && <Text>However, the shift has ended.</Text>}

      {isEditTimesheetTextVisible && (
        <Text>Don&apos;t worry - you can edit your timesheet at the end.</Text>
      )}
    </>
  );
}
