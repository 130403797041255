import { post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import {
  type CancelShiftRequest,
  cancelShiftRequestSchema,
  type CancelShiftResponse,
  cancelShiftResponseSchema,
} from "../types";

export const CANCEL_SHIFT_URL = "/v1/shifts/selfCancel";

export function useCancelShift(
  options: UseMutationOptions<CancelShiftResponse, AxiosError, CancelShiftRequest> = {}
): UseMutationResult<CancelShiftResponse, AxiosError, CancelShiftRequest> {
  return useMutation({
    mutationFn: async (params) => {
      const response = await post({
        url: CANCEL_SHIFT_URL,
        data: params,
        requestSchema: cancelShiftRequestSchema,
        responseSchema: cancelShiftResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CANCEL_SHIFT_FAILURE,
    },
    ...options,
  });
}
