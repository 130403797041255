import { ShiftSpecificationCategory } from "../types";

export function getShiftRequirementLabelByCategory(category: ShiftSpecificationCategory): string {
  switch (category) {
    case ShiftSpecificationCategory.GRADE_AND_SUBJECT: {
      return "Grade and subject";
    }

    case ShiftSpecificationCategory.AGE_GROUP: {
      return "Age group";
    }

    case ShiftSpecificationCategory.DRIVE_ROUTE: {
      return "Additional route information";
    }

    case ShiftSpecificationCategory.PATIENT_SCHEDULE: {
      return "Patient schedule";
    }

    default: {
      return "Additional information";
    }
  }
}
