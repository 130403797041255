import { isDefined } from "@clipboard-health/util-ts";
import { get } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import { type ShiftStateData, type ShiftStateDependencies, shiftStateSchema } from "../types";

interface GetShiftStateParams {
  shiftStateDependencies?: ShiftStateDependencies;
}

function getShiftStateEndpointUrl(shiftId: string) {
  return `/shift/${shiftId}/state`;
}

export function useGetShiftState(
  params: GetShiftStateParams,
  options?: UseQueryOptions<ShiftStateData>
): UseQueryResult<ShiftStateData> {
  const { shiftStateDependencies } = params;
  const isQueryEnabled = isDefined(shiftStateDependencies);

  let useQueryOptions: UseQueryOptions<ShiftStateData> = {
    enabled: false,
  };

  if (isQueryEnabled) {
    const shiftStateEndpointUrl = getShiftStateEndpointUrl(shiftStateDependencies._id);

    useQueryOptions = {
      queryKey: [shiftStateEndpointUrl, shiftStateDependencies],
      queryFn: async () => {
        const response = await get({
          url: shiftStateEndpointUrl,
          responseSchema: shiftStateSchema,
        });
        return response.data;
      },
      meta: {
        logErrorMessage: APP_V2_APP_EVENTS.GET_SHIFT_STATE_FAILURE,
      },
      ...options,
    };
  }

  return useQuery(useQueryOptions);
}
