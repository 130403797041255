import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import axios, { type AxiosError } from "axios";
import { v4 as uuid } from "uuid";

import {
  getPresignedUrlResponseSchema,
  type UploadShiftTimecardToS3Params,
  type UploadShiftTimecardToS3Response,
} from "../types";

async function getPresignedTimesheetUploadUrl(params: {
  shiftId: string;
  fileStorageFileKey: string;
}) {
  const { shiftId, fileStorageFileKey } = params;
  const response = await get({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/shift/${shiftId}/timesheet/${fileStorageFileKey}/presigned-upload-url`,
    responseSchema: getPresignedUrlResponseSchema,
  });
  // URL at document-storage-service-cbh-qa.s3.us-west-2.amazonaws.com
  return response.data.presigned_url;
}

export function useUploadShiftTimecardToS3(
  options: UseMutationOptions<
    UploadShiftTimecardToS3Response,
    AxiosError,
    UploadShiftTimecardToS3Params
  > = {}
): UseMutationResult<UploadShiftTimecardToS3Response, AxiosError, UploadShiftTimecardToS3Params> {
  return useMutation({
    mutationFn: async (params) => {
      const { fileBlob, shiftId, type } = params;
      const randomId = uuid();
      const fileStorageFileKey = `timesheet/${shiftId}/${randomId}.${type.split("/")[1]}`;
      const presignedUrl = await getPresignedTimesheetUploadUrl({ shiftId, fileStorageFileKey });

      // we avoid our API client here because the automatically added "Authorization" header conflicts with the AWS presigned URL
      // and we need to add a "Content-Type" header
      await axios.put(presignedUrl, fileBlob, {
        headers: {
          "Content-Type": type,
        },
      });

      return {
        url: fileStorageFileKey,
        filename: `${shiftId}.png`,
        fileStorageFileKey,
      };
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.UPLOAD_SHIFT_TIMECARD_TO_S3_FAILURE,
    },
    ...options,
  });
}
