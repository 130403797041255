import { Text } from "@clipboard-health/ui-react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Alert, AlertTitle } from "@mui/material";
import { lightBlue } from "@mui/material/colors";

import { type ShiftSpecificationCategory } from "../types";
import { getShiftRequirementLabelByCategory } from "./utils";

interface ShiftSpecificationAlertProps {
  shiftSpecifications: {
    category: ShiftSpecificationCategory;
    requirement: string;
  };
}

export function ShiftSpecificationAlert(props: ShiftSpecificationAlertProps) {
  const { shiftSpecifications } = props;
  return (
    <Alert severity="info" icon={<CheckCircleOutlineRoundedIcon fontSize="small" />}>
      <AlertTitle color={lightBlue[700]}>
        <Text color="inherit" variant="body2">
          {getShiftRequirementLabelByCategory(shiftSpecifications.category)}
        </Text>
      </AlertTitle>
      <Text variant="body2" color="text.primary">
        {shiftSpecifications.requirement}
      </Text>
    </Alert>
  );
}
